.profile-image {
    width: 30%;
    flex: 1;
    margin-right: 20px;
}

.profile-image img {
    max-width: 100%;
    border-radius: 15px;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.4);
}

.certification {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.certification-badge {
    width: 70px; /* Adjust the width as needed */
    height: auto;
    margin-right: 10px;
}

.certification-title {
    font-size: small;
    margin: 0;
}

.resume-downloads {
    display: flex;
    align-items: center;
    justify-content: center; /* Centering the downloads */
    margin-top: 10px;
    width: 100%;
}

.resume-downloads a {
    margin-left: 10px;
    display: inline-block;
    font-size: 24px; /* Adjust size as needed */
    color: #007bff; /* Adjust color as needed */
}

.resume-downloads a i {
    font-size: 24px; /* Ensure icon size is set */
}

header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;
    flex-direction: column; /* Stack items in column layout by default */
    text-align: center;
}

.header-content {
    display: flex;
    flex-direction: column;
    flex: 3;
    text-align: left;
}

header h1 {
    margin: 0;
    font-size: 2.0em;
    font-weight: bold;
}

header h2 {
    margin: 0;
    font-size: 1.0em;
    font-weight: normal;
    color: #555;
}

header h3 {
    margin: 0;
    font-size: .9em;
    font-weight: normal;
    color: #555;
}

@media (min-width: 1024px) {
    .resume-downloads {
        position: absolute;
        top: 10px;
        right: 10px;
        width: auto;
        justify-content: flex-end; /* Align items to the right */
        margin-top: 0;
    }

    header {
        flex-direction: row; /* Change to row layout for desktop */
        text-align: left;
    }
    
    .header-content {
        margin-left: 20px;
        position: absolute;
    }
}

@media (min-width: 600px) {

    .header-content {
        position: relative;
    }

    header h1 {
        font-size: 4.5em;
    }

    header h2 {
        font-size: 1.5em;
    }

    header h3 {
        font-size: 1.2em;
    }
}
