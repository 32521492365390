/* PrivacyPolicy.css */

.privacy-policy-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin: 20px;
    font-size: .9em;
  }
  
  .privacy-policy-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    text-align: left;
  }
  
  .privacy-policy-content {
    background: white;
    padding: 10px 0px 20px 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    position: relative;
    max-height: 80%;
    display: flex;
    flex-direction: column;
  }
  
  .privacy-policy-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background: white;
    padding: 5px 15px 0px 0px; /* Further reduced padding */
    margin-bottom: 5px 5px 0px 0px; /* Further reduce margin */
  }
  
  .privacy-policy-header h1 {
    font-size: 1.5rem; /* Reduce font size */
    margin: 0; /* Remove default margin */
    line-height: 1.2; /* Adjust line height */
  }
  
  .header-buttons {
    display: flex;
    gap: 0px; /* Reduce gap between buttons */
  }
  
  .icon-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .privacy-policy-scrollable {
    overflow-y: auto;
    flex: 1;
    margin-top: 10px;
  }
  
  .privacy-policy-inner {
    padding-right: 10px; /* Add padding to the right to create space between text and scrollbar */
  }
  
  /* Style the scrollbar */
  .privacy-policy-scrollable::-webkit-scrollbar {
    width: 6px; /* Make the scrollbar thin */
  }
  
  .privacy-policy-scrollable::-webkit-scrollbar-track {
    background: transparent; /* Make the track almost invisible */
  }
  
  .privacy-policy-scrollable::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2); /* Make the thumb slightly visible */
    border-radius: 3px; /* Round the corners of the scrollbar */
  }
  
  /* For Firefox */
  .privacy-policy-scrollable {
    scrollbar-width: thin; /* Make the scrollbar thin */
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* Make the scrollbar thumb slightly visible and track invisible */
  }
  
  .close-button {
    position: absolute;
    top: 5px; /* Adjust position to match reduced padding */
    right: 5px; /* Adjust position to match reduced padding */
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  