.section {
    padding: 10px; /* Padding */
    margin-bottom: 15px; /* Margin */
  }
  
  .section-content:hover {
    background-color: #fff; /* Background color on hover */
  }
  
  .section-title {
    font-size: 1.5em; /* Font size */
    font-weight: bold; /* Font weight */
    color: #333; /* Font color */
    margin-bottom: 10px; /* Spacing below the title */
    border-bottom: 2px solid #333; /* Border below the title */
    padding-bottom: 5px; /* Padding below the title */
  }
  
  .section-content {
    font-size: 1em; /* Font size */
    color: #2c2c2c; /* Font color */
    line-height: 1.6; /* Line height */
    transition: background-color 0.3s ease; /* Smooth transition */
  }

  .section-title {
    border-bottom: 2px solid #333;
    margin-bottom: 10px;
    font-size: 1.4em;
  }