body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  color: #333;
  background: url("/public/images/background.png") no-repeat center center fixed;
  background-size: cover;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow content to wrap */
}

.left-column, .right-column {
  flex: 0 0 48%; /* Adjust width as needed */
  padding: 10px;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

@media (max-width: 600px) {
  .content {
    flex-direction: column; /* Stack elements vertically */
  }

  .left-column, .right-column {
    flex: 1 0 100%; /* Take full width */
    padding: 10px 0; /* Add some padding for separation */
  }
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

ul li {
  margin: 5px 0;
}

strong {
  font-weight: bold;
}

.contact p,
.competencies ul,
.technologies ul {
  padding-left: 20px;
}

.footer {
  font-size: .8rem;
  text-align: center;
  padding: 10px;
}