.technology-skills-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 equal-width columns */
    gap: 10px;
    /* Space between items */
    padding: 20px;
}

.technology-skill-item {
    padding: 5px;
    background-color: #f0f0f0;
    /* Light gray background */
    border-radius: 4px;
    /* Rounded corners */
    text-align: center;
    font-size: 14px;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Light shadow */
}